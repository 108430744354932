import React, { useState, useRef, useEffect } from 'react';
import Header from '../components/Header';
import '../resources.css';

const AccordionItem = ({ question, answer, index, isOpen, toggleAccordion }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = '0px';
    }
  }, [isOpen]);

  return (
    <div className="accordion-item">
      <div className="accordion-question" onClick={() => toggleAccordion(index)}>
        <h3>{question}</h3>
      </div>
      <div className={`accordion-answer`} ref={contentRef}>
        <div className="accordion-answer-content" dangerouslySetInnerHTML={{ __html: answer }}></div>
      </div>
    </div>
  );
};

const AboutUsPage = () => {
  const [language, setLanguage] = useState('en');
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleLanguage = () => {
    setLanguage(prevLanguage => (prevLanguage === 'en' ? 'ru' : 'en'));
  };

  const toggleAccordion = index => {
    setOpenIndexes(prevIndexes => 
      prevIndexes.includes(index) 
        ? prevIndexes.filter(i => i !== index)
        : [...prevIndexes, index]
    );
  };

  const faqData = [
    {
      question: 'What\'s the current state of the blockchain/cryptocurrency space?',
      answer: `
        <p>It's been over 15 years since Satoshi Nakamoto released the Bitcoin Whitepaper. Today thousands of blockchains exist serving as payment systems and frameworks for logistics. Projects offer access to new technology such as decentralized finance, NFTs (non-fungible tokens), and DAOs (Decentralized Autonomous Organizations). What began as a peer-to-peer currency system has now evolved into an entirely new internet – Web3.</p>
        <p>Yet, despite ongoing innovations, Satoshi's original vision seems to have been overshadowed. Many new blockchains prioritize faster and cheaper transactions, often at the expense of the core principles of trust emphasized in the Bitcoin Whitepaper.</p>
        <p>Common industry sentiments include:</p>
        <ul>
          <li>We need to onboard the next billion users.</li>
          <li>The best blockchain will use the best technology.</li>
          <li>Blockchain fees price out users from the decentralized future.</li>
        </ul>
        <p>While everyone desires the best blockchain — one that is fast and affordable — this pursuit often overlooks the most crucial element: decentralization. The very reason cryptocurrency is necessary. Decentralization means that no single entity has control over the network, power is distributed among many participants, and decisions are made collectively.</p>
        <p>Today, out of thousands of blockchains, only a handful can truly be considered decentralized. Even among those, the definitions of decentralization vary greatly. Ideally, decentralization would mean that every single person in the world runs a node. In contrast, the worst-case scenario would be a network sustained by a single server, leading to security risks, increased vulnerability to attacks, and censorship. We can have both decentralization and the best technology, but this requires action and innovation.</p>
        <p>As the blockchain space continues to grow exponentially, decentralization has struggled to keep pace. If blockchain innovation ignores network stability, there will be no reason to use cryptocurrency over other currencies.</p>
      `,
    },
    {
      question: 'What\'s the inspiration behind Fiftysix?',
      answer: `
        <p>Decentralization is the cornerstone of blockchain technology and has been since Satoshi released the Bitcoin whitepaper. It ensures that no single entity controls the network, distributing power among many participants. This distribution is essential for maintaining trust, security, and resilience.</p>
        <p>We asked ourselves the following questions:</p>
        <ul>
          <li>How can we make the cryptocurrency space more accessible?</li>
          <li>What types of solutions benefit all actors: users, developers, researchers, companies, and investors?</li>
          <li>What can we do to address critical issues within the blockchain space that other companies are overlooking?</li>
        </ul>
        <p>Today, companies are racing to create the next blockchain or decentralized application (dApp) for mass adoption. These projects require immense effort and resources, involving team building, drafting whitepapers, eventually launching, and ongoing maintenance. Even after launch, continuous business development, developer acquisition, culture creation, and innovation are essential to keep the blockchain and its applications relevant.</p>
        <p>We realized that the most crucial element to these projects is often overlooked. To properly create and sustain a blockchain, teams need to ensure their network is efficient, resilient, and uncensorable. In essence, teams must prioritize decentralization. The two most valuable and powerful blockchains in the world, Bitcoin and Ethereum, derive their value from this network effect.</p>
        <p>Once a sufficiently decentralized network is achieved, users, developers, and researchers can focus on advancing on-chain technologies without worrying about the stability of the chain itself.</p>
        <p>A decentralized network is less vulnerable to attacks, censorship, and failures. It empowers communities to innovate freely, knowing that the foundation they build upon is solid and secure.</p>
        <p>While others focus on creating the best blockchain or dApp, we work alongside them to support the decentralization of their networks. The space will not reach mass blockchain adoption until its networks are properly distributed and resilient.</p>
        <p>Overall, Fiftysix believes in enabling a more secure, trustworthy, and innovative blockchain ecosystem by pioneering decentralization.</p>
      `,
    },
    {
      question: 'What does Fiftysix do?',
      answer: `
        <p>Our focus is on blockchain node provisioning to strengthen decentralization in the space. Many companies today provide nodes on behalf of users by running them on shared infrastructure, often relying on larger providers like Google Cloud or AWS. While this is convenient for users who lack the necessary machines, it creates single points of failure and undermines the core principles of blockchain decentralization.</p>
        <p>To address this, Fiftysix is adopting a different approach. We enable users to run blockchain nodes on their own infrastructure, ensuring ease of use while promoting network decentralization. By doing so, Fiftysix provides users with seamless access to interact with their necessary blockchain and simultaneously strengthen the underlying network – a true win-win scenario.</p>
        <p>Moreover, Fiftysix ensures that interactions with blockchain networks are as fluid as possible, improving the overall user experience managing nodes and cryptocurrencies.</p>
        <p>Fiftysix is developing open source software to achieve this. Find out more about Nodevin <a class="white" href="#/nodevin" target="_blank" rel="noopener noreferrer">here</a>.</p>
      `,
    },
    {
      question: 'Where is Fiftysix located?',
      answer: `
        <p>Fiftysix is a US based company, located 60 miles from New York City.</p>
      `,
    },
    {
      question: 'What is Nodevin?',
      answer: `
        <p>Nodevin is open source, command-line software designed to simplify the process of running and managing blockchain nodes.</p>
        <p>Whether you're a developer, institution, or enthusiast, Nodevin provides an easy and efficient way to participate in blockchain decentralization.
        <ul>
          <li><strong>Website:</strong> <a class="white" href="https://nodevin.xyz/" target="_blank" rel="noopener noreferrer">https://nodevin.xyz</a></li>
          <li><strong>Business:</strong> <a class="white" href="https://nodevin.xyz/#/business" target="_blank" rel="noopener noreferrer">https://nodevin.xyz/#/business</a></li>
        </ul>
        </p>
      `,
    },
    {
      question: 'What is the pricing for Nodevin?',
      answer: `
        <p>Nodevin is <strong>entirely free to use</strong>.</p>
        <p>Businesses interested in Nodevin software integration visit <a class="white" href="https://nodevin.xyz/#/business" target="_blank" rel="noopener noreferrer">here</a>.</p>
      `,
    },
    {
      question: 'How can I stay informed about everything Fiftysix?',
      answer: `
        <p><a class="white" href="https://x.com/fiftysixcrypto" target="_blank" rel="noopener noreferrer">Twitter/X</a> is the best place to keep up with Fiftysix news and updates.</p>
      `,
    },
    {
      question: 'What\'s next at Fiftysix?',
      answer: `
        <p>Looking ahead, Fiftysix plans to expand physical node infrastructure, introduce cryptocurrency staking to <a class="white" href="#/nodevin" target="_blank" rel="noopener noreferrer">Nodevin</a>, and more. These steps aim to improve blockchain decentralization and empower users.</p>
        <p>Fiftysix is dedicated to pioneering decentralization and building a more secure, trustworthy, and innovative blockchain environment.</p>
      `,
    },
  ];

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language} />
      <div className="sidebar">
        <div className="options">
          <div className="sidebar-option">
            <a className="white" href="/">Home</a>
          </div>
          <div className="sidebar-option sidebar-margin">
            <a className="white" href="#/nodevin">Nodevin</a>
          </div>
          <div className="sidebar-option sidebar-margin">
            <a className="white" href="#/resources">Resources</a>
          </div>
          <div className="sidebar-option sidebar-margin">
            <a className="white" href="#/manifesto">Manifesto</a>
          </div>
          <div className="sidebar-option sidebar-margin">
            <a className="selected" href="#/about-us"><u>{">"} About Us</u></a>
          </div>
          <div className="sidebar-option sidebar-margin">
            <a className="white" href="#/contact">Contact</a>
          </div>
        </div>
      </div>

      <div className="about-us-page-wrapper">
        <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image" />
        <h1 className="title">About Us</h1>
        <div className="sub-text-wrapper">
          <p className="sub-text">
            Learn more about Fiftysix.
          </p>
        </div>
        <div className="accordion">
          {faqData.map((item, index) => (
            <AccordionItem
              key={index}
              index={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndexes.includes(index)}
              toggleAccordion={toggleAccordion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
